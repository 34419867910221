/* common RD UI utilities
 * dependencies: lodash
 */

var RDJS = (function (window, document) {

  'use strict';


@@include('_helpers.js')

@@include('_toggles.js')

@@include('_tabs.js')

@@include('_sharedHeights.js')

@@include('_waypoints.js')

@@include('_sharing.js')

@@include('_truncators.js')


@@include('_misc.js')

  /* =initialize modules
   * ===================
   */

  var init = function () {
    // functions that must run before large modules
    // e.g. functions that output toggles that must then be initialized
    misc.combineTabs();
    misc.addNavDrilldownButtons();
    misc.createHomeSlideshowTabs();

    // large modules
    toggles.init();
    tabs.init();
    sharedHeights.init();
    waypoints.init();
    truncators.init();
    sharing.init();

    // misc modules
    misc.addMobileMenuToggle();
    misc.focusInputLabels();
    misc.addInputUtilityClasses();

    misc.addAccordionIndicators();

    document.documentElement.classList.remove('no-js');
    document.documentElement.classList.add('js');
  };


  /* =public
   * =======
   */

  return {
    xhr: xhr,
    toggles: toggles,
    tabs: tabs,
    sharedHeights: sharedHeights,
    waypoints: waypoints,
    sharing: sharing,
    init: init
  };

}(window, document));

RDJS.init();
